import { render, staticRenderFns } from "./InventoryTableShowBlock.vue?vue&type=template&id=33a1514f&scoped=true"
import script from "./InventoryTableShowBlock.vue?vue&type=script&lang=js"
export * from "./InventoryTableShowBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33a1514f",
  null
  
)

export default component.exports