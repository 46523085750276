<template>
  <div class="fragment">

    <div class="table-card__item-content custom-margin-top"
         v-for="(itemNoMixed, indexNoMixed) in item.data.UserProductCellArray"
         :key="indexNoMixed + 'no-mix' + indexNoMixed"
         :class="{show: show}"
    >
      <div class="table-card__item-head">
        <div class="table-card__item-head-row d-flex">
          <div class="table-card__item-number table-card__item-number--small">
            <ValueHelper
                :value="item"
                :deep="'data.Product.product.productObject.sku_code'"
            />
          </div>
        </div>
      </div>
      <transition name="slide">
        <div class="table-card__item-body"
             v-if="show"
        >
          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_UPC.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="item"
                    :deep="'data.Product.product.productObject.upc_code'"
                />
              </div>
            </div>
            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{ $t(`inventory_ProductName.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <TooltipTextHelper :fullTextWidth="360">
                  <template slot="text">
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.name'"
                    />
                  </template>
                  <template slot="paragraph">
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.name'"
                    />
                  </template>
                </TooltipTextHelper>
              </div>
            </div>

            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_FBM.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                {{ itemNoMixed.data.storageCount }}
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50"></div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_scannedTable.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <div class="table-row table-row--input">
                  {{itemNoMixed.data.cellCount}}
                </div>
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_AfterCount.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <div class="color-red">
                  {{ getAfterCount(itemNoMixed.data.cellCount, itemNoMixed.data.storageCount) }}
                </div>
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_Cell.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                {{ itemNoMixed.data.cellSelected.combo_name }}
              </div>
            </div>

          </div>
        </div>
      </transition>
      <div class="table-card__item-footer">
        <div class="table-card__item-show"
             @click="show = !show"
        >
          {{$t('common_showMore.localization_value.value')}}
        </div>
      </div>
    </div>
    <div class="table-card__item-content custom-margin-top"
         v-for="(itemMixed, indexMixed) in item.data.UserMixedProductCellArray"
         :key="indexMixed + 'mix' + indexMixed"
         :class="{show: show}"
    >
      <div class="table-card__item-head">
        <div class="table-card__item-head-row d-flex">
          <div class="table-card__item-number table-card__item-number--small">
            <ValueHelper
                :value="item"
                :deep="'data.Product.product.productObject.sku_code'"
            />
          </div>
        </div>
      </div>
      <transition name="slide">
        <div class="table-card__item-body"
             v-if="show"
        >
          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_UPC.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="item"
                    :deep="'data.Product.product.productObject.upc_code'"
                />
              </div>
            </div>
            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{ $t(`inventory_ProductName.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <TooltipTextHelper :fullTextWidth="360">
                  <template slot="text">
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.name'"
                    />
                  </template>
                  <template slot="paragraph">
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.name'"
                    />
                  </template>
                </TooltipTextHelper>
              </div>
            </div>

            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_FBM.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                {{ itemMixed.data.storageCount }}
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50"></div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_scannedTable.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <div class="table-row table-row--input">
                  {{itemMixed.data.cellCount}}
                </div>
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_AfterCount.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <div class="color-red">
                  {{ getAfterCount(itemMixed.data.cellCount, itemMixed.data.storageCount) }}
                </div>
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_Cell.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                {{ itemMixed.data.cellSelected.combo_name }}
              </div>
            </div>

          </div>
        </div>
      </transition>
      <div class="table-card__item-footer">
        <div class="table-card__item-show"
             @click="show = !show"
        >
          {{$t('common_showMore.localization_value.value')}}
        </div>
      </div>
    </div>
    <div class="table-card__item-content"
         v-if="isEmptyProduct(item)"
         :class="{show: show}"
    >
      <div class="table-card__item-head">
        <div class="table-card__item-head-row d-flex">
          <div class="table-card__item-number table-card__item-number--small">
            <ValueHelper
                :value="item"
                :deep="'data.Product.product.productObject.sku_code'"
            />
          </div>
        </div>
      </div>
      <transition name="slide">
        <div class="table-card__item-body"
             v-if="show"
        >
          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_UPC.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="item"
                    :deep="'data.Product.product.productObject.upc_code'"
                />
              </div>
            </div>
            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{ $t(`inventory_ProductName.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <TooltipTextHelper :fullTextWidth="360">
                  <template slot="text">
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.name'"
                    />
                  </template>
                  <template slot="paragraph">
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.name'"
                    />
                  </template>
                </TooltipTextHelper>
              </div>
            </div>

            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_FBM.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                {{item.data.withoutCellDeclaredCounts}}
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50"></div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_scannedTable.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <div class="table-row table-row--input">
                  {{item.data.withoutCellEndCounts}}
                </div>
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_AfterCount.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <div class="color-red">
                  {{ getAfterCount(item.data.withoutCellEndCounts, item.data.withoutCellDeclaredCounts) }}
                </div>
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_Cell.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                —
              </div>
            </div>

          </div>
        </div>
      </transition>
      <div class="table-card__item-footer">
        <div class="table-card__item-show"
             @click="show = !show"
        >
          {{$t('common_showMore.localization_value.value')}}
        </div>
      </div>
    </div>
    <div class="table-card__item-content"
         v-if="(item.data.withoutCellDeclaredCounts !== 0 || item.data.withoutCellEndCounts !== 0) && item.data.withoutCellDeclaredCounts !== null"
         :class="{show: show}"
    >
      <div class="table-card__item-head">
        <div class="table-card__item-head-row d-flex">
          <div class="table-card__item-number table-card__item-number--small">
            <ValueHelper
                :value="item"
                :deep="'data.Product.product.productObject.sku_code'"
            />
          </div>
        </div>
      </div>
      <transition name="slide">
        <div class="table-card__item-body"
             v-if="show"
        >
          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_UPC.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="item"
                    :deep="'data.Product.product.productObject.upc_code'"
                />
              </div>
            </div>
            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{ $t(`inventory_ProductName.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <TooltipTextHelper :fullTextWidth="360">
                  <template slot="text">
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.name'"
                    />
                  </template>
                  <template slot="paragraph">
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.name'"
                    />
                  </template>
                </TooltipTextHelper>
              </div>
            </div>

            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_FBM.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                {{ item.data.withoutCellDeclaredCounts }}
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50"></div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_scannedTable.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <div class="table-row table-row--input">
                  {{item.data.withoutCellEndCounts}}
                </div>
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_AfterCount.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <div class="color-red">
                  —
                </div>
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_Cell.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                {{ getAfterCount(item.data.withoutCellEndCounts, item.data.withoutCellDeclaredCounts) }}
              </div>
            </div>

          </div>
        </div>
      </transition>
      <div class="table-card__item-footer">
        <div class="table-card__item-show"
             @click="show = !show"
        >
          {{$t('common_showMore.localization_value.value')}}
        </div>
      </div>
    </div>
  </div>
<!--  <div class="table-card__item-content"-->
<!--       :class="{show: show}"-->
<!--  >-->
<!--    <div class="table-card__item-head">-->
<!--      <div class="table-card__item-head-row d-flex">-->
<!--        <div class="table-card__item-number table-card__item-number&#45;&#45;small">-->
<!--          <ValueHelper-->
<!--              :value="item"-->
<!--              :deep="'data.Product.product.productObject.sku_code'"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <transition name="slide">-->
<!--      <div class="table-card__item-body"-->
<!--           v-if="show"-->
<!--      >-->
<!--        <div class="table-card__item-row custom-row">-->
<!--          <div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
<!--            <div class="table-card__item-label">-->
<!--             {{ $t(`inventory_SKU.localization_value.value`) }}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--              <ValueHelper-->
<!--                  :value="item"-->
<!--                  :deep="'data.Product.product.productObject.sku_code'"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
<!--            <div class="table-card__item-label">-->
<!--            {{ $t(`inventory_UPC.localization_value.value`) }}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--              <ValueHelper-->
<!--                  :value="item"-->
<!--                  :deep="'data.Product.product.productObject.upc_code'"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="table-card__item-col custom-col">-->
<!--            <div class="table-card__item-label">-->
<!--            {{ $t(`inventory_ProductName.localization_value.value`) }}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--              <TooltipTextHelper :fullTextWidth="360">-->
<!--                <template slot="text">-->
<!--                  <ValueHelper-->
<!--                      :value="item"-->
<!--                      :deep="'data.Product.product.productObject.name'"-->
<!--                  />-->
<!--                </template>-->
<!--                <template slot="paragraph">-->
<!--                  <ValueHelper-->
<!--                      :value="item"-->
<!--                      :deep="'data.Product.product.productObject.name'"-->
<!--                  />-->
<!--                </template>-->
<!--              </TooltipTextHelper>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
<!--            <div class="table-card__item-label">-->
<!--            {{ $t(`inventory_FBM.localization_value.value`) }}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--              0-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="table-card__item-col custom-col custom-col&#45;&#45;50"></div>-->
<!--          <div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
<!--            <div class="table-card__item-label">-->
<!--            {{ $t(`inventory_scannedTable.localization_value.value`) }}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--              23-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
<!--            <div class="table-card__item-label">-->
<!--              {{ $t(`inventory_AfterCount.localization_value.value`) }}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--                        <span class="color-red">-->
<!--                          +12-->
<!--                        </span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
<!--            <div class="table-card__item-label">-->
<!--             {{ $t(`inventory_Cell.localization_value.value`) }}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--              8-->
<!--            </div>-->
<!--          </div>-->


<!--        </div>-->
<!--      </div>-->
<!--    </transition>-->
<!--    <div class="table-card__item-footer">-->
<!--      <div class="table-card__item-show"-->
<!--           @click="show = !show"-->
<!--      >-->
<!--        {{$t('common_showMore.localization_value.value')}}-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import {inventoryMixin} from "@/mixins/inventoryMixins/inventoryMixin";
export default {
name: "InventoryTableMobile",
  components: {ValueHelper, TooltipTextHelper},

  mixins: [inventoryMixin],

  props: {
    item: Object,
  },

  data() {
    return {
      show: false,
    }
  },

}
</script>

<style scoped>

.custom-margin-top + .custom-margin-top{
  margin-top: 15px;
}
</style>