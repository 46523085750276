<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="horiz-block">
      <div class="horiz-block__inner">
        <div class="filter-parcel-track">
          <div class="filter-parcel-track__item"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'inventory_ScanNumber',
        ])"></div>
            <DefaultInput
                :label="$t('inventory_ScanNumber.localization_value.value')"
                :type="'text'"
                v-model="scan"
                @change="changeScanner"
            />
          </div>
<!--          <div class="filter-parcel-track__item">-->
<!--            <DefaultInput-->
<!--                :label="'Scan Number'"-->
<!--                :type="'text'"-->
<!--                v-model="input02"-->
<!--            />-->
<!--          </div>-->
        </div>
      </div>
    </div>

  </div>
</template>



<script>
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";

  export default {
    name: "InventoryHead",
    components: {
      // SearchEngine,
      DefaultInput,
    },

    props: {
      Inventory: Object,
    },

    data(){
      return{
        scan: '',
      }
    },

    methods: {
      changeScanner(val){
        if(val.length > 0){
          let foundIndex = null

          // delete this.Inventory.data.productsForCellArray[0].color

          this.Inventory.data.productsForCellArray.map((item, index) => {
            if(item.data.Product.product.productObject.upc_code === val && foundIndex === null){
              item.addScannedProductInventory()
              foundIndex = index
            }
          })

          if(foundIndex !== null) {
            let copyProductForCell = this._.clone(this.Inventory.data.productsForCellArray[foundIndex])
            this.Inventory.data.productsForCellArray.splice(foundIndex, 1)
            this.Inventory.data.productsForCellArray.unshift(copyProductForCell)

            // this.Inventory.data.productsForCellArray[0].color = true

          }
        }

        this.scan = ''
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";
  @import "../../../../../../../scss/colors";

  .horiz-block{
    &__inner{
      padding-top: 40px;
      display: flex;
      width: 100%;
    }

    .filter-parcel-track{
      display: flex;
      width: 100%;

      @include for-1400{
        flex-wrap: wrap;
      }

      &__item{
        width: 100%;
        margin-bottom: 15px;

        @include from-1400 {
          margin-right: 30px;
          max-width: 644px;

          &:last-child{
            margin-right: 0;
          }
        }

        @include for-1120{
          width: 100%;
        }


      }
    }
  }

</style>

