import {checkValid} from "@/components/globalModels/functions/ModelsValidate";
import {Product} from "@/components/globalModels/Product";
import {ProductForCell} from "@/components/globalModels/ProductForCell";
import {ProductCells} from "@/components/globalModels/ProductCells";
import {User} from "@/components/globalModels/User";
import {Order} from "@/components/globalModels/Order";
import _ from 'lodash'
import {INVENTORY_TYPES, WAREHOUSE_STORAGE_DOCUMENT_TYPES} from "@/staticData/staticVariables";


export function Inventory() {

  this.checkValid = checkValid

  this.data = {
    Order: new Order(),
    User: new User(),
    requestedDate: '',
    productsForCellArray: [],
    warehouse: null,
  }

}

Inventory.prototype.setInventoryItem = function (inventoryData, products, typeShow = false) {

  this.data.Order.setId(inventoryData.id)
  this.data.Order.setStatus(inventoryData.status)
  this.data.User.setUserId(inventoryData.user_id)
  this.data.requestedDate = inventoryData.requested_date
  this.data.warehouse = inventoryData.warehouse_storage
  this.setInventoryProductCells(products, inventoryData, typeShow)

}

Inventory.prototype.setInventoryProductCells = function (products, inventoryData, typeShow) {
  if (Object.keys(products).length > 0) {

    products.map((product) => {

      let {
        newProduct,
        cells,
      } = this.createProductModelAndCellsArray(product, inventoryData, typeShow)

      cells.userProductCells.map(userProductCell => {
        /**
         * create newCellObject (костыль, чтобы сделать для каждой ячейки продукта отдельный обьект productsForCellArray)
         * таким образом каждая запись в таблице это не продукт с ячеками, а 1 продукт с 1й ячейкой
         * теперь можна по массиву перекидывать и сортировать по ячейкам
         */
        let newCellObject = {
          userProductCells: [userProductCell],
          userCells: [],
          freeCells: [],
          mixedFreeCells: [],
        }

        /**
         * set proformProduct to ProductForCell model
         * and add to productsForCellArray array
         */
        //let newIndex =
          this.addProductForCells(newProduct, newCellObject, product.count_damaged)
        /**
         * Add Product Count Without Cell
         */
        //this.addProductCountWithoutCell(product, this.data.productsForCellArray[newIndex], inventoryData, typeShow)


      })


      // /**
      //  * set proformProduct to ProductForCell model
      //  * and add to productsForCellArray array
      //  */
      // let newIndex = this.addProductForCells(newProduct, cells, product.count_damaged)
      //
      // // console.log(existCell);
      // // console.log(newIndex);
      // /**
      //  * Add Product Count Without Cell
      //  */
      // this.addProductCountWithoutCell(product, this.data.productsForCellArray[newIndex], inventoryData, typeShow)


    })

    this.data.productsForCellArray = this.sortProductsArrayByCells(this.data.productsForCellArray)

    // set checked inventory items
    inventoryData.lines.map(inventoryItem => {
      if(inventoryItem.is_checked) {
        let cellId = inventoryItem.cell_id
        let productId = inventoryItem.product_id
        let flagFound = false
        this.data.productsForCellArray.map(productForCell => {
          let userCell = productForCell.data.UserProductCellArray
          let userProductId = productForCell.data.Product.product.id

          if(parseInt(userProductId) !== parseInt(productId) || flagFound) return

          if(cellId && userCell.length > 0 && userCell[0].data.cellSelected.id === cellId) {
            productForCell.data.checked = true
            flagFound = true
          }

          if(!cellId && userCell.length === 0) {
            productForCell.data.checked = true
            flagFound = true
          }

        })
      }
    })

  }
}

Inventory.prototype.sortProductsArrayByCells = function (productsForCellArray) {
  let productsWithCells = []
  let productsWithoutCells = []

  productsForCellArray.map(productForCell => {
    let userProductCells = productForCell.data.UserProductCellArray
    let userMixedProductCells = productForCell.data.UserMixedProductCellArray
    if(userProductCells.length > 0 || userMixedProductCells.length > 0) {
      productsWithCells.push(productForCell)
    } else {
      productsWithoutCells.push(productForCell)
    }
  })

  let sortedProductsWithCells = []
  sortedProductsWithCells = _.sortBy(productsWithCells, (productWithCells) => {
    return _.first(productWithCells.data.UserMixedProductCellArray)?.data.cellSelected.id
  })

  sortedProductsWithCells = _.sortBy(sortedProductsWithCells, (productWithCells) => {
    return _.first(productWithCells.data.UserProductCellArray)?.data.cellSelected.id
  })

  return [...sortedProductsWithCells, ...productsWithoutCells]
}

Inventory.prototype.createProductModelAndCellsArray = function (product, inventoryData, typeShow) {
  /**
   * create and Product model
   * @type {Product}
   */
  let newProduct = new Product()
  newProduct.setProduct(product, {
    id: product.id,
    name: product.name,
  })

  let userProductCells = []
  let userCells = []
  let freeCells = []
  let mixedFreeCells = []

  userProductCells = [...userProductCells, ...this.addCellsWithProductInWarehouse(product, inventoryData, typeShow)]

  return {newProduct, cells: {userProductCells, userCells, freeCells, mixedFreeCells}}
}

Inventory.prototype.addProductForCells = function (product = false, cells = false, countDamaged = false) {
  let productForCell = new ProductForCell(product, false, countDamaged)

  if (cells.userProductCells.length) {
    /**
     * Если был переведен в комплит и списан но найден в истории изменений то записываем как withoutCellDeclaredCounts (костыль)
     */
    if (cells.userProductCells.length === 1 && !cells.userProductCells[0].data.cellSelected && cells.userProductCells[0].data.storageCount !== 0) {
      productForCell.data.withoutCellDeclaredCounts = cells.userProductCells[0].data.storageCount
    }
    /**
     * Если товара не было на складе и но его добавили то записываем как withoutCellEndCounts (костыль)
     */
    if (cells.userProductCells.length === 1 && !cells.userProductCells[0].data.cellSelected &&
      cells.userProductCells[0].data.storageCount === 0 && cells.userProductCells[0].data.cellCount) {
      productForCell.data.withoutCellEndCounts = cells.userProductCells[0].data.cellCount
    }

    productForCell.setCellsByTypeInbound(cells)
  }

  // if without cell - set scanned
  if(productForCell.data.withoutCellDeclaredCounts !== 0 && productForCell.data.scannedCount !== 0) {
    productForCell.data.withoutCellEndCounts = productForCell.data.scannedCount
  }

  this.data.productsForCellArray.push(productForCell)
  return this.data.productsForCellArray.length - 1
}

Inventory.prototype.addCellsWithProductInWarehouse = function (currentProduct, inventoryData, typeShow) {
  let inventorySaveItems = inventoryData.lines
  /**
   * Добавляем ячейки в которых еще есть этот продукт на складе
   * Map product -> items_in_storage
   * if exist cell in storage and not selected =>
   * add this cell to productsForCellArray
   */
  let cells = []
  let cellCountBefore = false

  if (currentProduct?.items_in_storage.length === 0) {
    let cellCount = false

    /**
     * Если находим обнуленный товар то записываем как cellCountBefore (storageCount) (костыль)
     */
    let foundZeroCountInSaveItems = _.find(inventorySaveItems, {product_id: currentProduct.id})
    if (foundZeroCountInSaveItems?.quantity === 0 && typeShow) {
      cellCountBefore = foundZeroCountInSaveItems.calc_quantity_before
    }

    /**
     * Если находим новый товар то записываем как cellCount (костыль)
     */
    if (foundZeroCountInSaveItems?.calc_quantity_before === 0 && foundZeroCountInSaveItems?.quantity !== 0) {
      cellCount = foundZeroCountInSaveItems?.quantity
    }

    let cell = new ProductCells(false, false, cellCountBefore, cellCount)
    cells.push(cell)

    return cells
  }

  currentProduct?.items_in_storage.map(productInWarehouse => {
    let cellCount = false

    if (productInWarehouse.damage) return

    cellCountBefore = productInWarehouse.quantity

    let saveCellData = _.find(inventorySaveItems, {
      cell_id: productInWarehouse.cell?.id,
      product_id: productInWarehouse.product_id
    })
    if (saveCellData) {
      cellCount = saveCellData.quantity
      if (typeShow) {
        cellCountBefore = saveCellData.calc_quantity_before
      }
    }
    console.log(444);
    console.log(productInWarehouse);
    console.log(saveCellData);

    if(!saveCellData && !cellCount) {
      let saveWithoutCellData = _.find(inventorySaveItems, {
        cell_id: null,
        product_id: productInWarehouse.product_id
      })
      if(saveWithoutCellData) {
        cellCount = saveWithoutCellData.quantity
        console.log(555);
        console.log(saveWithoutCellData.quantity);
        if(typeShow) {
          cellCountBefore = saveWithoutCellData.calc_quantity_before
        }
      }
    }
    // console.log(2222);
    // console.log(cellCountBefore);
    // console.log(cellCount);

    let cell = new ProductCells(false, productInWarehouse.cell, cellCountBefore, cellCount)

    cells.push(cell)
  })

  /**
   * check product where quantity before > 0 and quantity after === 0
   */
  if(this.data.Order.data.status === 'completed') {
    inventorySaveItems.map(inventoryItem => {
      if(inventoryItem.product_id === currentProduct.id && inventoryItem.calc_quantity_before > 0 && inventoryItem.quantity === 0){
        let cell = new ProductCells(false, inventoryItem.cell, inventoryItem.calc_quantity_before, inventoryItem.quantity)
        cells.push(cell)
      }
    })
  }

  return cells
}

Inventory.prototype.addProductCountWithoutCell = function (product, productForCell, inventoryData, typeShow) {
  let inventorySaveItems = inventoryData.lines

  product.items_in_storage.map(productInWarehouse => {
    if (!productInWarehouse.cell && productInWarehouse.cell_id) return
    if (productInWarehouse.damage) return

    if (!productInWarehouse.cell) {
      productForCell.data.withoutCellDeclaredCounts = parseInt(productInWarehouse.quantity)
    }

    let saveCellData = _.find(inventorySaveItems, {cell_id: null, product_id: productInWarehouse.product_id})
    if (saveCellData) {
      productForCell.data.withoutCellEndCounts = saveCellData.quantity
      if (typeShow) {
        productForCell.data.withoutCellDeclaredCounts = saveCellData.calc_quantity_before
      }
    }

  })
}

Inventory.prototype.addProductExistCountWithoutCell = function (product, productForCell) {
  product.cells.map(cell => {
    if (!cell.cell && cell.cell_id) return

    if (!cell.cell) {
      productForCell.data.withoutCellEndCounts = parseInt(productForCell.data.withoutCellEndCounts) + parseInt(cell.item_quantity)
    }
  })
}

Inventory.prototype.productsDataGeneration = function (draft = false) {


  let itemData = {
    "storage_id": this.data.warehouse?.id,
    "inventory_type": INVENTORY_TYPES.GENERAL,
    "type_id": WAREHOUSE_STORAGE_DOCUMENT_TYPES.TYPE_INVENTORY,
    "user_id": this.data.User.getUserId(),
    "status": draft ? 'draft' : 'completed',
    "comment": null,
    "costs": null,
    "requested_date": this.data.requestedDate,
    "products": [],
  }

  let cells = []

  this.data.productsForCellArray.map(item => {

    let product = item.data.Product.product
    let productId = product?.id

    if (!productId) return

    let userProductCellArray = item.data.UserProductCellArray,
      userMixedProductCellArray = item.data.UserMixedProductCellArray

    cells = [...cells, ...this.getCellsFromArray(userProductCellArray, productId, true, item.data.checked)]
    cells = [...cells, ...this.getCellsFromArray(userMixedProductCellArray, productId, true, item.data.checked)]

    if (item.data.withoutCellEndCounts !== 0 || item.data.withoutCellDeclaredCounts !== 0) {
      cells.push({
        "product_id": productId,
        "cell_id": null,
        "quantity": parseInt(item.data.withoutCellEndCounts),
        "damage": false,
        "is_checked": item.data.checked,
      })
    }

    // if(cells.length > 0) {
    //   itemData["products"].push(cells)
    // }
  })

  itemData["products"] = cells

  return itemData
}

Inventory.prototype.getCellsFromArray = function (array, productId, allItems = false, checked = false) {
  let cells = []
  array.map(itemCell => {
    if (itemCell.data.cellCount > 0 || allItems) {
      cells.push({
        "product_id": productId,
        "cell_id": itemCell.data.cellSelected.id,
        "quantity": parseInt(itemCell.data.cellCount),
        "damage": false,
        "is_checked": checked,
      })
    }
  })

  return cells
}