<template>
  <div class="fragment">
    <InventoryTableBlock
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @resetFilter="resetFilter"
        @showMore="showMore"
        @changeFilter="changeFilter"
        @reload="reload"
    />
  </div>

</template>

<script>
  import InventoryTableBlock from "./InventoryTableBlock/InventoryTableBlock";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";
  import {INVENTORY_TYPES} from "@/staticData/staticVariables";

  export default {
    name: "InventoryTable",
    components: {
      InventoryTableBlock,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getStorageDocumentsForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterRequestedDate: '',
        filterUpdatedDate: '',
        filterStatus: '',
        filterUserId: '',
        filterUserName: '',
        filterBalance: '',

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          requestedDate: this.$route.query.requestedDate,
          updatedDate: this.$route.query.updatedDate,
          status: this.$route.query.status,
          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          userBalance: this.$route.query.userBalance,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextStorageDocumentsPage', true)
        this.$store.dispatch('fetchStorageDocuments', url).then(() => {
          this.$store.commit('setNextStorageDocumentsPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        if (this.filterUpdatedDate.length > 0) {
          let date = this.generateDateForFilter(this.filterUpdatedDate, 'YYYY-MM-DD')
          myQuery.whereIn('updatedAt', date)
          this.filterStatus = 'completed'
        }

        this.generateFilterQueryParams(
          myQuery,
          {
            filterUserId: 'user_id',
            filterId: 'id',
            // filterRequestedDate: 'requestedDate',
            // filterUpdatedDate: 'updatedAt',
            filterStatus: 'status',
          },
        )

        if (this.filterRequestedDate.length > 0) {
          let date = this.generateDateForFilter(this.filterRequestedDate, 'YYYY-MM-DD')
          myQuery.whereIn('requestedDate', date)
        }

        myQuery.where('inventory_type', INVENTORY_TYPES.GENERAL)

        // if(this.filterStatus === 'true') myQuery.where('ForbiddenItemKeywordIdiIsEnabled', '1')

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },

    }

  }
</script>

<style scoped>

</style>
