<template>
  <div class="fragment">
    <div class="site-table-wrap small-table mt-0"
         v-if="filteredArray.length > 0"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'inventory_SKU',
          'inventory_UPC',
          'inventory_ProductName',
          'inventory_FBM',
          'inventory_scannedTable',
          'inventory_AfterCount',
          'inventory_Cell',
        ])"></div>
      <table class="site-table" v-if="!isMobileFunc()">
        <thead>
        <tr>
          <th>{{ $t(`inventory_SKU.localization_value.value`) }}</th>
          <th>{{ $t(`inventory_UPC.localization_value.value`) }}</th>
          <th>{{ $t(`inventory_ProductName.localization_value.value`) }}</th>
          <th>{{ $t(`inventory_FBM.localization_value.value`) }}</th>
          <th>{{ $t(`inventory_scannedTable.localization_value.value`) }}</th>
          <th>{{ $t(`inventory_AfterCount.localization_value.value`) }}</th>
          <th v-if="isAdmin">{{ $t(`inventory_Cell.localization_value.value`) }}</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(item, index) in filteredArray">
          <template>
            <tr :key="index">
              <td>
                <ValueHelper
                    :value="item"
                    :deep="'data.Product.product.productObject.sku_code'"
                />
              </td>
              <td>
                <ValueHelper
                    :value="item"
                    :deep="'data.Product.product.productObject.upc_code'"
                />
              </td>
              <td>
                <div class="table-row">
                  <TooltipTextHelper :fullTextWidth="360">
                    <template slot="text">
                      <ValueHelper
                          :value="item"
                          :deep="'data.Product.product.productObject.name'"
                      />
                    </template>
                    <template slot="paragraph">
                      <ValueHelper
                          :value="item"
                          :deep="'data.Product.product.productObject.name'"
                      />
                    </template>
                  </TooltipTextHelper>
                </div>
              </td>
              <td>
              {{ getWarehouseCount(item) }}
<!--                {{ itemMixed.data.storageCount }}-->
              </td>
              <td>
                <div class="table-row table-row--input">
                  {{ getScannedCount(item) }}
<!--                  {{ itemMixed.data.cellCount }}-->
                </div>
              </td>
              <td>
                <div class="color-red">
                  {{ getAfterCount(getScannedCount(item), getWarehouseCount(item)) }}
                </div>
              </td>
              <td  v-if="isAdmin">
<!--                {{ itemMixed.data.cellSelected.combo_name }}-->
              </td>
            </tr>
          </template>

        </template>
        </tbody>
      </table>

      <div class="table-card" v-if="isMobileFunc()">
        <div class="table-card__list">
          <div class="table-card__item"
               v-for="(item, index) in filteredArray"
               :key="index">
            <InventoryTableMobileForUser
                :item="item"
            />
          </div>
        </div>
      </div>

    </div>

    <template
        v-else>
      <NoResult
          :countFilterParams="0"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
      />
<!--          @resetFilter="$emit('resetFilter')"-->
    </template>

  </div>
</template>

<script>
import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
import {inventoryMixin} from "@/mixins/inventoryMixins/inventoryMixin";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
import NoResult from "@/components/coreComponents/NoResult/NoResult";
import InventoryTableMobileForUser
  from "@/components/modules/InventoryModule/components/InventoryTableShow/InventoryTableShowBlock/InventoryTable/InventoryTableMobileForUser/InventoryTableMobileForUser";


export default {
  name: "InventoryTableForUser",

  components: {
    InventoryTableMobileForUser,
    NoResult,
    TooltipTextHelper,
    ValueHelper,
  },

  mixins: [mixinDetictingMobile, inventoryMixin],

  props: {
    filteredArray: Array,
  },



  data() {
    return {

    }
  },

  methods: {
  },


}

</script>

<style scoped lang="scss">
.comment-row {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 297px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}


@media (max-width: 1900px) {
  .comment-row {
    width: 250px;
  }
}

@media (max-width: 1400px) {
  .comment-row {
    width: 200px;
  }
}

.small-table th,
.small-table td {
  white-space: nowrap;
}


</style>
