import { render, staticRenderFns } from "./InventoryTable.vue?vue&type=template&id=1c50029e&scoped=true"
import script from "./InventoryTable.vue?vue&type=script&lang=js"
export * from "./InventoryTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c50029e",
  null
  
)

export default component.exports