<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'inventory_all',
          'inventory_edited',
          'inventory_unscanned',
          'inventory_scanned',
        ])"></div>
      <div class="content-tabs content-tabs--separator">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              {{item.label}}
            </div>
          </div>
        </template>
      </div>
    </div>

    <NewInventarPopup
      v-if="isModalNewInventarPopup"
      @close="changeNewInventarPopup(false)"
    />

  </div>
</template>



<script>
  // import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  // import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  // import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  // import DatePicker from 'vue2-datepicker';
  import NewInventarPopup from "../../../../popups/NewInventarPopup/NewInventarPopup";


  export default {
    name: "InventoryHead",
    components: {
      // ToggleFilterButton,
      // MainButton,
      // SearchEngine,
      // DefaultSelect,
      // DatePickerDefault,
      // DatePicker,
      NewInventarPopup,
    },

    props: {
      navTabs: Object,
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
        tab: this.activeTab,
        countFilterParams: 0,
        input01: '',
        options: [{}],
        date: '',
        isModalNewInventarPopup: false,
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeDate() {
        console.log('changeDate function');
      },

      changeNewInventarPopup(val){
        this.isModalNewInventarPopup = val
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";
  @import "../../../../../../../scss/colors";

  .content-tabs {
    @include for-768 {
      padding-top: 0;
    }
  }

/*
  .light-bg-block{
    min-height: 58px;
    @include for-1120{
      align-items: initial;
    }

    @include for-680{
      flex-direction: column;
    }
  }

  .btn-left-block{
    width: 100%;
  }

  .btn-right-block{
    @include for-680 {
      margin-top: -50px;
    }
  }
*/

  .filter-parcel-track{
    display: flex;
    width: 100%;

    @include for-1120{
      flex-direction: column;
    }

    @include for-680{
      margin-bottom: 15px;
    }

    &__item{
      max-width: 314px;
      width: 100%;
      min-width: 220px;
      display: flex;
      margin-right: 15px;
      align-items: center;

      &.wfc{
        min-width: initial;
      }

      @include for-1120{
        margin-bottom: 15px;

        &:last-child{
          margin-bottom: 0;
        }
      }

      @include for-680{
        max-width: 100%;
      }


      button{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        height: 40px;
      }
    }
  }

  .horiz-block{
    padding-top: 24px;

    &__inner{
      display: flex;
      align-items: center;
      position: relative;

      @include for-1120{
        align-items: flex-start;
      }

      @include for-680{
        flex-direction: column;
      }
    }

    &__btn{
      @include from-680 {
        margin-left: auto;
      }

      button{
        font-size: 16px;
        line-height: 19px;
        min-width: 110px;
        height: 40px;

        @include for-680 {
          margin-left: 0!important;
        }
      }
    }
  }

  .accept-mn-link{
    padding: 8px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $accent;
    transition: .15s;

    &:hover{
      opacity: .7;
    }

    &__ico{
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

</style>

