<template>
  <div>
    <div class="mt-3 font-weight-bold" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'inventory_FBM',
        ])"></div>
      {{$t('inventory_FBM.localization_value.value')}}: {{Inventory.data.warehouse.name}}
    </div>

    <InventoryHead
        :Inventory="Inventory"
    />

    <div class="table-filter-wrap">

      <InventoryTable
          :Inventory="Inventory"
          @save="data => $emit('save', data)"
      />
    </div>
  </div>
</template>

<script>


import InventoryHead from "./InventoryHead/InventoryHead";
import InventoryTable from "./InventoryTable/InventoryTable";

export default {
  name: "InventoryTableUser",

  components: {
    InventoryHead,
    InventoryTable,
  },

  props: {
    Inventory: Object,
  },

  data() {
    return {}
  },

  methods: {

  }
}
</script>

<style scoped>

</style>
