<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">
<!--        <div class="content-tabs__item " v-bind:class="{active: tab === 1}" @click="changeTab(1)">-->
<!--          <div class="content-tabs__ico">-->
<!--           -->
<!--          </div>-->
<!--        </div>-->
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-right-block">

        <div class="horiz-block__btn"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
            'inventory_StartNew',
          ])"></div>
          <MainButton class="btn-fit-content ml-3 white-space-nowrap"
                      :value="$t('inventory_StartNew.localization_value.value')"
                      @click.native="changeNewInventarPopup(true)"
          />
        </div>
      </div>
    </div>

    <NewInventarPopup
        v-if="isModalNewInventarPopup"
        @reload="$emit('reload')"
        @close="changeNewInventarPopup(false)"
    />

  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import NewInventarPopup from "@/components/modules/InventoryModule/popups/NewInventarPopup/NewInventarPopup";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "InventoryHead",
    components: {
      MainButton,
      NewInventarPopup,
      ToggleFilterButton,
      // DefaultCheckbox,
      // SearchEngine,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    data(){
      return{
        isModalNewInventarPopup: false,
      }
    },

    methods: {

      changeNegativeBalancePopup(val, id = false) {
        if(id){
          this.orderId = id
        }
        this.isModalNegativeBalancePopup = val

        if(!val){
          this.orderId = -1
        }
      },

      changeNewInventarPopup(val){
        this.isModalNewInventarPopup = val
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";


</style>

