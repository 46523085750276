<template>
  <modal
      @close="$emit('close')"
      class="custom-popup w400"
  >
    <template slot="header">
     <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
       <div class="admin-edit" @click="editTranslate([
            'inventory_NewInventarization',
          ])"></div>
       {{ $t(`inventory_NewInventarization.localization_value.value`) }}
     </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
          <div class="custom-row">
            <div class="custom-col"
                 v-if="isAdmin">
              <UserSelect
                  :serverError="false"
                  :validationUser="User.validation.userId"
                  :validationTxtUser="User.validationTxt.userId"
                  :validationTranslateUser="User.validationTranslate.userId"
                  :userSelected="User.user"
                  :typeSelect="'users'"
                  :userEmail="true"
                  @changeUser="changeUser"
              />
            </div>
            <div class="custom-col">
              <RadioDefault
                  class="white-space-line width-initial mr-3"
                  v-for="(item, index) in warehouses"
                  :key="index"
                  :label="item.name"
                  :name="'warehouse'"
                  :value="selectedWarehouse && item.id === selectedWarehouse.id"
                  @input="changeWarehouse(item)"
              />
            </div>
            <div class="custom-col">
              <DatePickerDefault
                  :label="'Date'"
                  v-model="date"
                  class="w-100 color-fix"

                  v-bind:class="{'ui-no-valid': validation.date}"
                  :errorTxt="$t(`error_required.localization_value.value`)"
                  :error="validation.date"
              >
                <template slot="body">
                  <date-picker
                      v-model="date"
                      ref="datePicker"
                      valueType="format"
                      :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                      :format="'MM/DD/YY'"
                      :placeholder="'mm/dd/yy'"
                      :disabled="true"
                  ></date-picker>
                </template>
              </DatePickerDefault>
            </div>
          </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_cancel',
            'common_submit',
          ])"></div>

        <span class="site-link site-link--alt mr-3 mb-2 mt-2"
              @click="$emit('close')"
        >
              {{ $t(`common_cancel.localization_value.value`) }}
        </span>

        <MainButton
            class="scoped-width"
            :value="$t('common_submit.localization_value.value')"
            @click.native="createInventoryRequest"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DatePicker from 'vue2-datepicker';
  import DatePickerDefault from "../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import UserSelect from "@/components/coreComponents/UserSelect/UserSelect";
  import {User} from "@/components/globalModels/User";
  import {INVENTORY_TYPES} from "@/staticData/staticVariables";
  import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";

  export default {
    name: "NewInventarPopup",
    components: {
      RadioDefault,
      UserSelect,
      Modal,
      MainButton,
      DatePicker,
      DatePickerDefault,
    },

    props: [

    ],

    data(){
      return{
        options: [{}],
        warehouses: [],
        selectedWarehouse: [],
        date: '',
        validation: {
          date: false
        },

        User: new User(),
      }
    },

    mounted() {
      this.date = this.$moment(new Date()).format('MM/DD/YY')

      this.$store.dispatch('fetchWarehouseStorage').then((response) => {
        this.warehouses = this.getRespPaginateData(response)
        this.selectedWarehouse = this._.first(this.warehouses)
      })

    },

    methods: {

      changeWarehouse(val) {
        this.selectedWarehouse = val
      },

      changeUser(val) {
        this.User.setUser(val)
      },

      createInventoryRequest() {
        if(this.date === ''){
          this.validation.date = true
          return
        } else {
          this.validation.date = false
        }

        if(this.isAdmin && !this.User.userValidation({userId: true})){
          return
        }

        let data = {
          "storage_id": this.selectedWarehouse.id,
          "user_id": this.isAdmin ? this.User.getUserId() : this.loadUserAuthorizedData?.user?.id,
          "type_id": 40,
          "inventory_type": INVENTORY_TYPES.GENERAL,
          "status": "draft",
          "requested_date": this.$moment(this.date).format('YYYY-MM-DD'),
          "comment": ""
        }

        this.$store.dispatch('createInventoryRequest', data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('reload')
              this.$emit('close')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        })

      }

    },

  }

</script>

<style lang="scss" scoped>
/deep/ .color-fix .mx-input:disabled, .mx-input.disabled {
  color: black;
}
</style>
