<template>
  <div>

    <div class="mt-3 font-weight-bold">
      Warehouse: {{Inventory.data.warehouse.name}}
    </div>

    <InventoryHead
        :navTabs="navTabs"
        @changeTab="data => $emit('changeTab', data)"
    />

    <div class="table-filter-wrap">

<!--      <InventoryFilter-->
<!--          :showFilter="showFilter"-->
<!--          @close="closeFilter"-->
<!--          @changeFilter="changeFilter"-->
<!--          @resetFilter="$emit('resetFilter')"-->
<!--      />-->

      <InventoryTable
          v-if="isAdmin"
          :Inventory="Inventory"
          :filteredArray="filteredArray"
      />
      <InventoryTableForUser
          v-else
          :Inventory="Inventory"
          :filteredArray="filteredArray"
      />
    </div>
  </div>
</template>

<script>


  import InventoryHead from "./InventoryHead/InventoryHead";
  // import InventoryFilter from "./InventoryFilter/InventoryFilter";
  import InventoryTable from "./InventoryTable/InventoryTable";
  import InventoryTableForUser from "./InventoryTable/InventoryTableForUser";

  export default {
    name: "InventoryTableShowBlock",

    components: {
      InventoryHead,
      // InventoryFilter,
      InventoryTable,
      InventoryTableForUser,
    },

    props: {
      Inventory: Object,
      filteredArray: Array,
      navTabs: Object,
    },

    data(){
      return{
      }
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>
