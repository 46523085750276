
export const inventoryMixin = {

  methods: {
    getAfterCount(cellCount, storageCount) {
      if (cellCount - storageCount === 0) {
        return 0
      }
      if (cellCount - storageCount > 0) {
        return '+' + (cellCount - storageCount)
      } else {
        return '-' + (cellCount - storageCount) * -1
      }
    },

    isEmptyProduct(item) {
      if (
        parseInt(item.data.withoutCellDeclaredCounts) === 0 &&
        (parseInt(item.data.withoutCellEndCounts) === 0 || item.data.withoutCellEndCounts === '') &&
        item.data.UserProductCellArray.length === 0 &&
        item.data.UserMixedProductCellArray.length === 0
      ) {
        return true
      } else {
        return false
      }
    },


    getWarehouseCount(item) {
      let count = 0

      item.data.UserMixedProductCellArray.map(cell => {
        count += parseInt(cell.data.storageCount)
      })

      item.data.UserProductCellArray.map(cell => {
        count += parseInt(cell.data.storageCount)
      })

      count += parseInt(item.data.withoutCellDeclaredCounts)

      return count
    },

    getScannedCount(item) {
      let count = 0

      item.data.UserMixedProductCellArray.map(cell => {
        count += parseInt(cell.data.cellCount)
      })

      item.data.UserProductCellArray.map(cell => {
        count += parseInt(cell.data.cellCount)
      })

      count += parseInt(item.data.withoutCellEndCounts)

      return count
    },
  }

}
