<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <div class="table-card__item-number table-card__item-number--small">
          #{{item.id}}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('inventory_id.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <router-link
                  :to="$store.getters.GET_PATHS.mainSettingsInventory + '/show/' + item.id"
                  class="table-link btn-style">
                #{{item.id}}
              </router-link>
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('inventory_date.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.requested_date | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{ $t('common_user.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn :item="item" />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('inventory_status.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.status === 'completed'">
                <StatusIcoBtn :type="'active'" class="mr-1"/>
                {{$t('inventory_Completed.localization_value.value')}}
              </template>
              <template v-if="item.status === 'draft'">
                <StatusIcoBtn :type="'in-transit'" class="mr-1"/>
                {{$t('inventory_inProcess.localization_value.value')}}
              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('inventory_dateCompleted.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <span v-if="item.status === 'completed'">
                <ValueHelper
                    :value="item['updated_at']"
                    :date="true"
                    :dateType="'DD MMM, YYYY'"
                />
              </span>
              <span v-else>—</span>
            </div>
          </div>
          <div class="table-card__item-col custom-col"
               v-if="isAdmin && item.status !== 'completed'">
            <div class="table-card__item-label">
              {{ $t('common_manage.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
                <router-link
                    :to="$store.getters.GET_PATHS.mainSettingsInventory + '/edit/' + item.id">
                  <LinkButton
                      :value="$t(`common_edit.localization_value.value`)"
                      :type="'edit'"
                  />
                </router-link>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
  import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
  export default {
    name: "InventoryTableMobile",
    components: {LinkButton, ValueHelper, StatusIcoBtn, TableUserColumn},

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,
      }
    }
  }
</script>

<style scoped>

</style>
