<template>
  <div class="fragment">

    <div class="table-card__item-content"
         :class="{show: show}"
    >
      <div class="table-card__item-head">
        <div class="table-card__item-head-row d-flex">
          <div class="table-card__item-number table-card__item-number--small">
            <ValueHelper
                :value="item"
                :deep="'data.Product.product.productObject.sku_code'"
            />
          </div>
        </div>
      </div>
      <transition name="slide">
        <div class="table-card__item-body"
             v-if="show"
        >
          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_UPC.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="item"
                    :deep="'data.Product.product.productObject.upc_code'"
                />
              </div>
            </div>
            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{ $t(`inventory_ProductName.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <TooltipTextHelper :fullTextWidth="360">
                  <template slot="text">
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.name'"
                    />
                  </template>
                  <template slot="paragraph">
                    <ValueHelper
                        :value="item"
                        :deep="'data.Product.product.productObject.name'"
                    />
                  </template>
                </TooltipTextHelper>
              </div>
            </div>

            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_FBM.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                {{ getWarehouseCount(item) }}
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50"></div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_scannedTable.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <div class="table-row table-row--input">
                  {{ getScannedCount(item) }}
                </div>
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{ $t(`inventory_AfterCount.localization_value.value`) }}
              </div>
              <div class="table-card__item-info">
                <div class="color-red">
                  {{ getAfterCount(getScannedCount(item), getWarehouseCount(item)) }}
                </div>
              </div>
            </div>

          </div>
        </div>
      </transition>
      <div class="table-card__item-footer">
        <div class="table-card__item-show"
             @click="show = !show"
        >
          {{$t('common_showMore.localization_value.value')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import {inventoryMixin} from "@/mixins/inventoryMixins/inventoryMixin";
export default {
name: "InventoryTableMobileForUser",
  components: {ValueHelper, TooltipTextHelper},

  mixins: [inventoryMixin],

  props: {
    item: Object,
  },

  data() {
    return {
      show: false,
    }
  },

}
</script>

<style scoped>

.custom-margin-top + .custom-margin-top{
  margin-top: 15px;
}
</style>